import { Button } from '@/components/ui/atoms/button'
import { useStore } from '@nanostores/react'
import { atom } from 'nanostores'

const videoAtom = atom<boolean>(false)

export default function OurContent() {
  const $video = useStore(videoAtom)

  return (
    <section className="relative isolate bg-gray-900 overflow-hidden py-8 sm:py-12">
      <div className="prose prose-invert p-3 max-w-screen-xl mx-auto flex flex-col md:flex-row gap-10">
        <svg
          viewBox="0 0 1024 1024"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          aria-hidden="true"
        >
          <circle
            cx={512}
            cy={512}
            r={512}
            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
            fillOpacity="0.7"
          />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stopColor="#172554" />
              <stop offset={1} stopColor="#0369a1" />
            </radialGradient>
          </defs>
        </svg>
        <div className="basis-6/12">
          <h2>
            Quer saber como são os
            <b> nossos materiais?</b>
          </h2>
          <p>
            Os cursos são <b>focados no seu edital</b> e nas características da
            sua banca examinadora. Você saberá quais são as{' '}
            <b>aulas mais importantes, temas mais cobrados</b> e o que você deve
            <b> priorizar</b>.
          </p>
          <Button
            variant={'default'}
            asChild
            className="rounded-md no-underline bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            <a href="/cursos" aria-label="cursos">
              Comece agora
            </a>
          </Button>
        </div>
        <div className="basis-6/12 sm:flex sm:items-end">
          {$video ? (
            <div className="my-8">
              <iframe
                title="Institucional - Sonhos"
                loading="lazy"
                width="640"
                height="342"
                src="https://www.youtube.com/embed/-7csBfdMhds?autoplay=1&rel=0&showinfo=0&controls=0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </div>
          ) : (
            <>
              <img
                loading="lazy"
                src="/images/home-video.svg"
                alt="Meus Sonhos"
                width={640}
                height={359}
                className="rounded-2xl cursor-pointer"
                onClick={() => videoAtom.set(!$video)}
                onKeyDown={() => {}}
              />
            </>
          )}
        </div>
      </div>
    </section>
  )
}
